import React, { useState } from 'react';
import Header from '../../../Components/Header';
import Checkbox from '../../../Components/Checkbox';
import styles from './style.module.scss';

function CodigoPenalPage() {
    // Estado para armazenar os dados do preso
    const [nomePreso, setNomePreso] = useState('');
    const [idPreso, setIdPreso] = useState('');
    const [rgAdvogado, setRgAdvogado] = useState('');
    const [resumosArtigos, setResumosArtigos] = useState({});
    const [penaTotal, setPenaTotal] = useState(0);
    const [multaTotal, setMultaTotal] = useState(0);
    const [fiancaTotal, setFiancaTotal] = useState(0);

    // Estados para armazenar o status dos checkboxes
    const [checkboxes, setCheckboxes] = useState({
        artigo1_1: false,
        artigo1_2: false,
        artigo1_3: false,
        artigo1_4: false,
        artigo2_3: false,
        artigo3_1: false,
        artigo3_2: false,
        artigo3_3: false,
        artigo3_4: false,
        artigo3_5: false,
        artigo4_1: false,
        artigo4_2: false,
        artigo4_3: false,
        artigo5_1: false,
        artigo5_2: false,
        artigo5_3: false,
        artigo5_4: false,
        artigo5_5: false,
        artigo5_6: false,
        artigo5_7: false,
        artigo5_8: false,
        artigo5_9: false,
        artigo5_10: false,
        artigo5_11: false,
        artigo5_12: false,
        artigo5_13: false,
        artigo5_14: false,
        artigo5_15: false,
        artigo5_16: false,
        artigo5_17: false,
        artigo6_1: false,
        artigo6_2: false,
        artigo6_3: false,
        artigo6_4: false,
        artigo6_5: false,
        artigo6_6: false,
        artigo6_7: false,
        artigo6_8: false,
        artigo6_10: false,
        artigo6_11: false,
        artigo6_12: false,
        artigo6_13: false,
        artigo6_14: false,
        artigo6_15: false,
        artigo6_16: false,
        artigo6_17: false,
        artigo6_18: false,
        artigo6_19: false,
        artigo6_20: false,
        artigo6_21: false,
        artigo6_22: false,
        artigo6_23: false,
        artigo6_24: false,
        artigo6_25: false,
        artigo6_26: false,
        artigo6_27: false,
        artigo6_28: false,
        artigo6_29: false,
        artigo6_30: false,
        artigo6_31: false,
        artigo6_32: false,
        artigo6_33: false,
        artigo6_34: false,
        artigo7_1: false,
        artigo7_2: false,
        artigo7_3: false,
        artigo7_4: false,
        artigo7_5: false,
        artigo7_6: false,
        artigo7_7: false,
        artigo7_8: false,
        artigo7_9: false,
        artigo7_10: false,
    });

    // Função para atualizar o estado com o nome do preso
    const handleNomePresoChange = (event) => {
        setNomePreso(event.target.value);
    };

    // Função para atualizar o estado com o ID do preso
    const handleIDPresoChange = (event) => {
        setIdPreso(event.target.value);
    };

    // Função para atualizar o estado com o RG do advogado
    const handleRgAdvogadoChange = (event) => {
        setRgAdvogado(event.target.value);
    };

    // Função para lidar com a mudança nos checkboxes
    const handleCheckboxChange = (event, checkboxId) => {
        const { checked } = event.target;

        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [checkboxId]: checked,
        }));

        updateResumosArtigos(checkboxId, checked);
        updatePenaeMultaTotal(checkboxId, checked);
    };

    const updatePenaeMultaTotal = (checkboxId, isChecked) => {
        const penaArtigo = getPenaArtigo(checkboxId); // Implemente a lógica para obter a pena do artigo
        const multaArtigo = getMultaArtigo(checkboxId); // Implemente a lógica para obter a multa do artigo
        const fiancaArtigo = getMultaArtigo(checkboxId); // Implemente a lógica para obter a multa do artigo

        let fiancaAjustada = fiancaArtigo * 1.2;

        if (isChecked) {
            // Adiciona a pena e multa do artigo selecionado
            setPenaTotal((prevPenaTotal) => prevPenaTotal + penaArtigo);
            setMultaTotal((prevMultaTotal) => prevMultaTotal + multaArtigo);
            setFiancaTotal((prevFiancaTotal) => prevFiancaTotal + fiancaAjustada);
        } else {
            // Subtrai a pena e multa do artigo desmarcado
            setPenaTotal((prevPenaTotal) => prevPenaTotal - penaArtigo);
            setMultaTotal((prevMultaTotal) => prevMultaTotal - multaArtigo);
            setFiancaTotal((prevFiancaTotal) => prevFiancaTotal - fiancaAjustada);
        }
    };

    // Função para atualizar os resumos dos artigos marcados
    const updateResumosArtigos = (checkboxId, isChecked) => {
        setResumosArtigos((prevResumos) => {
            const updatedResumos = { ...prevResumos };

            if (isChecked) {
                // Adiciona o resumo correspondente se ainda não estiver presente
                if (!updatedResumos[checkboxId]) {
                    updatedResumos[checkboxId] = getResumoArtigo(checkboxId); // Implemente a lógica para obter o resumo do artigo
                }
            } else {
                // Remove o resumo correspondente se estiver presente
                delete updatedResumos[checkboxId];
            }

            return updatedResumos;
        });
    };

    // Função para obter a pena associada ao artigo
    const getPenaArtigo = (checkboxId) => {
        // Implemente a lógica para obter a pena do artigo com base no checkboxId
        switch (checkboxId) {
            case 'artigo1_1':
                return 10;;
            case 'artigo1_2':
                return 10;
            case 'artigo1_3':
                return 10;
            case 'artigo1_4':
                return 10;
            case 'artigo2_1':
                return 10;
            case 'artigo2_2':
                return 10;
            case 'artigo2_3':
                return 10;
            case 'artigo3_1':
                return 10;
            case 'artigo3_2':
                return 10;
            case 'artigo3_3':
                return 10;
            case 'artigo3_4':
                return 10;
            case 'artigo3_5':
                return 10;
            case 'artigo4_1':
                return 10;
            case 'artigo4_2':
                return 10;
            case 'artigo4_3':
                return 10;
            case 'artigo5_1':
                return 10;
            case 'artigo5_2':
                return 10;
            case 'artigo5_3':
                return 10;
            case 'artigo5_4':
                return 10;
            case 'artigo5_5':
                return 10;
            case 'artigo5_6':
                return 10;
            case 'artigo5_7':
                return 10;
            case 'artigo5_8':
                return 10;
            case 'artigo5_9':
                return 10;
            case 'artigo5_10':
                return 10;
            case 'artigo5_11':
                return 10;
            case 'artigo5_12':
                return 10;
            case 'artigo5_13':
                return 10;
            case 'artigo5_14':
                return 10;
            case 'artigo5_15':
                return 10;
            case 'artigo5_16':
                return 10;
            case 'artigo5_17':
                return 10;
            case 'artigo6_1':
                return 10;
            case 'artigo6_2':
                return 10;
            case 'artigo6_3':
                return 10;
            case 'artigo6_4':
                return 10;
            case 'artigo6_5':
                return 10;
            case 'artigo6_6':
                return 10;
            case 'artigo6_7':
                return 10;
            case 'artigo6_8':
                return 10;
            case 'artigo6_10':
                return 10;
            case 'artigo6_11':
                return 10;
            case 'artigo6_12':
                return 10;
            case 'artigo6_13':
                return 10;
            case 'artigo6_14':
                return 10;
            case 'artigo6_15':
                return 10;
            case 'artigo6_16':
                return 10;
            case 'artigo6_17':
                return 10;
            case 'artigo6_18':
                return 10;
            case 'artigo6_19':
                return 10;
            case 'artigo6_20':
                return 10;
            case 'artigo6_21':
                return 10;
            case 'artigo6_22':
                return 10;
            case 'artigo6_23':
                return 10;
            case 'artigo6_24':
                return 10;
            case 'artigo6_25':
                return 10;
            case 'artigo6_26':
                return 10;
            case 'artigo6_27':
                return 10;
            case 'artigo6_28':
                return 10;
            case 'artigo6_29':
                return 10;
            case 'artigo6_30':
                return 10;
            case 'artigo6_31':
                return 10;
            case 'artigo6_32':
                return 10;
            case 'artigo6_33':
                return 10;
            case 'artigo6_34':
                return 10;
            case 'artigo7_1':
                return 10;
            case 'artigo7_2':
                return 10;
            case 'artigo7_3':
                return 10;
            case 'artigo7_4':
                return 10;
            case 'artigo7_5':
                return 10;
            case 'artigo7_6':
                return 10;
            case 'artigo7_7':
                return 10;
            case 'artigo7_8':
                return 10;
            case 'artigo7_9':
                return 10;
            case 'artigo7_10':
                return 10;
            default:
                return '';
        }
    };

    const getMultaArtigo = (checkboxId) => {
        // Implemente a lógica para obter a multa do artigo com base no checkboxId
        switch (checkboxId) {
            case 'artigo1_1':
                return 10000;;
            case 'artigo1_2':
                return 10000;
            case 'artigo1_3':
                return 10000;
            case 'artigo1_4':
                return 10000;
            case 'artigo2_1':
                return 10000;
            case 'artigo2_2':
                return 10000;
            case 'artigo2_3':
                return 10000;
            case 'artigo3_1':
                return 10000;
            case 'artigo3_2':
                return 10000;
            case 'artigo3_3':
                return 10000;
            case 'artigo3_4':
                return 10000;
            case 'artigo3_5':
                return 10000;
            case 'artigo4_1':
                return 10000;
            case 'artigo4_2':
                return 10000;
            case 'artigo4_3':
                return 10000;
            case 'artigo5_1':
                return 10000;
            case 'artigo5_2':
                return 10000;
            case 'artigo5_3':
                return 10000;
            case 'artigo5_4':
                return 10000;
            case 'artigo5_5':
                return 10000;
            case 'artigo5_6':
                return 10000;
            case 'artigo5_7':
                return 10000;
            case 'artigo5_8':
                return 10000;
            case 'artigo5_9':
                return 10000;
            case 'artigo5_10':
                return 10000;
            case 'artigo5_11':
                return 10000;
            case 'artigo5_12':
                return 10000;
            case 'artigo5_13':
                return 10000;
            case 'artigo5_14':
                return 10000;
            case 'artigo5_15':
                return 10000;
            case 'artigo5_16':
                return 10000;
            case 'artigo5_17':
                return 10000;
            case 'artigo6_1':
                return 10000;
            case 'artigo6_2':
                return 10000;
            case 'artigo6_3':
                return 10000;
            case 'artigo6_4':
                return 10000;
            case 'artigo6_5':
                return 10000;
            case 'artigo6_6':
                return 10000;
            case 'artigo6_7':
                return 10000;
            case 'artigo6_8':
                return 10000;
            case 'artigo6_10':
                return 10000;
            case 'artigo6_11':
                return 10000;
            case 'artigo6_12':
                return 10000;
            case 'artigo6_13':
                return 10000;
            case 'artigo6_14':
                return 10000;
            case 'artigo6_15':
                return 10000;
            case 'artigo6_16':
                return 10000;
            case 'artigo6_17':
                return 10000;
            case 'artigo6_18':
                return 10000;
            case 'artigo6_19':
                return 10000;
            case 'artigo6_20':
                return 10000;
            case 'artigo6_21':
                return 10000;
            case 'artigo6_22':
                return 10000;
            case 'artigo6_23':
                return 10000;
            case 'artigo6_24':
                return 10000;
            case 'artigo6_25':
                return 10000;
            case 'artigo6_26':
                return 10000;
            case 'artigo6_27':
                return 10000;
            case 'artigo6_28':
                return 10000;
            case 'artigo6_29':
                return 10000;
            case 'artigo6_30':
                return 10000;
            case 'artigo6_31':
                return 10000;
            case 'artigo6_32':
                return 10000;
            case 'artigo6_33':
                return 10000;
            case 'artigo6_34':
                return 10000;
            case 'artigo7_1':
                return 10000;
            case 'artigo7_2':
                return 10000;
            case 'artigo7_3':
                return 10000;
            case 'artigo7_4':
                return 10000;
            case 'artigo7_5':
                return 10000;
            case 'artigo7_6':
                return 10000;
            case 'artigo7_7':
                return 10000;
            case 'artigo7_8':
                return 10000;
            case 'artigo7_9':
                return 10000;
            case 'artigo7_10':
                return 10000;
            default:
                return '';
        }
    };


    // Função para obter o resumo de um artigo específico
    const getResumoArtigo = (checkboxId) => {
        // Lógica para retornar o resumo de acordo com o checkboxId
        // Aqui você pode retornar o resumo diretamente ou buscar de um objeto/dados
        switch (checkboxId) {
            case 'artigo1_1':
                return 'Art. 1.1 - Homicídio Doloso Qualificado';
            case 'artigo1_2':
                return 'Art. 1.2 - Homicídio Doloso';
            case 'artigo1_3':
                return 'Art. 1.3 - Tentativa de Homicídio';
            case 'artigo1_4':
                return 'Art. 1.4 - Homicídio Culposo';
            case 'artigo2_1':
                return 'Art. 2.1 - Lesão Corporal';
            case 'artigo2_2':
                return 'Art. 2.2 - Sequestro';
            case 'artigo2_3':
                return 'Art. 2.3 - Cárcere Privado';
            case 'artigo3_1':
                return 'Art. 3.1 - Desmanche de Veículos';
            case 'artigo3_2':
                return 'Art. 3.2 - Furto';
            case 'artigo3_3':
                return 'Art. 3.3 - Receptação de Veículos';
            case 'artigo3_4':
                return 'Art. 3.4 - Roubo de Veículos';
            case 'artigo3_5':
                return 'Art. 3.5 - Furto de Veículos';
            case 'artigo4_1':
                return 'Art. 4.1 - Roubo';
            case 'artigo4_2':
                return 'Art. 4.2 - Furto a caixa eletrônico';
            case 'artigo4_3':
                return 'Art. 4.3 - Extorsão';
            case 'artigo5_1':
                return 'Art. 5.1 - Posse de peças de armas';
            case 'artigo5_2':
                return 'Art. 5.2 - Posse de Cápsula';
            case 'artigo5_3':
                return 'Art. 5.3 - Tráfico de Armas';
            case 'artigo5_4':
                return 'Art. 5.4 - Porte de Arma Pesada';
            case 'artigo5_5':
                return 'Art. 5.5 - Porte de Arma Leve';
            case 'artigo5_6':
                return 'Art. 5.6 - Disparo de Arma';
            case 'artigo5_7':
                return 'Art. 5.7 - Tráfico de Munições (+100)';
            case 'artigo5_8':
                return 'Art. 5.8 - Porte de Munição (-100)';
            case 'artigo5_9':
                return 'Art. 5.9 - Posse de Colete';
            case 'artigo5_10':
                return 'Art. 5.10 - Posse de Arma Branca';
            case 'artigo5_11':
                return 'Art. 5.11 - Tráfico de Drogas (+100)';
            case 'artigo5_12':
                return 'Art. 5.12 - Aviãozinho (6 a 100)';
            case 'artigo5_13':
                return 'Art. 5.13 - Posse de Componentes Narcóticos';
            case 'artigo5_14':
                return 'Art. 5.14 - Posse de Drogas (1 a 5)';
            case 'artigo5_15':
                return 'Art. 5.15 - Dinheiro Sujo';
            case 'artigo5_16':
                return 'Art. 5.16 - Contrabando';
            case 'artigo5_17':
                return 'Art. 5.17 - Descaminho';
            case 'artigo6_1':
                return 'Art. 6.1 - Falsidade ideológica';
            case 'artigo6_2':
                return 'Art. 6.2 - Formação de quadrilha';
            case 'artigo6_3':
                return 'Art. 6.3 - Apologia ao crime';
            case 'artigo6_4':
                return 'Art. 6.4 - Posse de arma em público';
            case 'artigo6_5':
                return 'Art. 6.5 - Suborno';
            case 'artigo6_6':
                return 'Art. 6.6 - Ameaça';
            case 'artigo6_7':
                return 'Art. 6.7 - Falsa comunicação de crime';
            case 'artigo6_8':
                return 'Art. 6.8 - Uso indevido de 190/192';
            case 'artigo6_10':
                return 'Art. 6.10 - Posse de itens ilegais';
            case 'artigo6_11':
                return 'Art. 6.11 - Assédio moral';
            case 'artigo6_12':
                return 'Art. 6.12 - Atentado ao pudor';
            case 'artigo6_13':
                return 'Art. 6.13 - Vandalismo';
            case 'artigo6_14':
                return 'Art. 6.14 - Dano ao Patrimônio Público';
            case 'artigo6_15':
                return 'Art. 6.15 - Invasão de propriedade';
            case 'artigo6_16':
                return 'Art. 6.16 - Abuso de autoridade';
            case 'artigo6_17':
                return 'Art. 6.17 - Uso de máscara';
            case 'artigo6_18':
                return 'Art. 6.18 - Uso de equipamentos restritos';
            case 'artigo6_19':
                return 'Art. 6.19 - Omissão de socorro';
            case 'artigo6_20':
                return 'Art. 6.20 - Tentativa de fuga';
            case 'artigo6_21':
                return 'Art. 6.21 - Desacato 1';
            case 'artigo6_22':
                return 'Art. 6.22 - Desacato 2';
            case 'artigo6_23':
                return 'Art. 6.23 - Desacato 3';
            case 'artigo6_24':
                return 'Art. 6.24 - Resistência a prisão';
            case 'artigo6_25':
                return 'Art. 6.25 - Réu Reincidente';
            case 'artigo6_26':
                return 'Art. 6.26 - Cúmplice';
            case 'artigo6_27':
                return 'Art. 6.27 - Obstrução à Justiça';
            case 'artigo6_28':
                return 'Art. 6.28 - Ocultação de Provas';
            case 'artigo6_29':
                return 'Art. 6.29 - Zaralho em recrutamento policial';
            case 'artigo6_30':
                return 'Art. 6.30 - Prisão Militar';
            case 'artigo6_31':
                return 'Art. 6.31 - Prevaricação';
            case 'artigo6_32':
                return 'Art. 6.32 - Invasão de Departamento Policial';
            case 'artigo6_33':
                return 'Art. 6.33 - Vadiagem';
            case 'artigo6_34':
                return 'Art. 6.34 - Desobediência';
            case 'artigo7_1':
                return 'Art. 7.1 - Condução imprudente';
            case 'artigo7_2':
                return 'Art. 7.2 - Dirigir na contra mão';
            case 'artigo7_3':
                return 'Art. 7.3 - Alta velocidade';
            case 'artigo7_4':
                return 'Art. 7.4 - Poluição sonora';
            case 'artigo7_5':
                return 'Art. 7.5 - Corridas Ilegais';
            case 'artigo7_6':
                return 'Art. 7.6 - Uso excessivo de insulfilm';
            case 'artigo7_7':
                return 'Art. 7.7 - Veículo muito danificado';
            case 'artigo7_8':
                return 'Art. 7.8 - Veículo Ilegalmente estacionado';
            case 'artigo7_9':
                return 'Art. 7.11 - Impedir o fluxo do tráfego';
            case 'artigo7_10':
                return 'Art. 7.12 - Colisão Proposital em viatura policial';
            default:
                return '';
        }
    };

    // Função para lidar com a submissão do formulário
    const handleSubmit = (event) => {
        event.preventDefault();
        // Lógica para processar os dados do preso e enviar ao Discord
    };


    return (
        <div className="App">
            <Header />
            <div className={styles.contato}>
                <h2 className={styles.sobre}>Codigo Penal</h2>
                <form onSubmit={handleSubmit}>
                    <div className={styles.dados}>
                        <div className={styles.input_container}>
                            <label>NOME</label>
                            <input
                                type="text"
                                placeholder="Digite o nome do preso..."
                                name="nomedopreso"
                                value={nomePreso}
                                onChange={handleNomePresoChange}
                            />
                        </div>
                        <div className={styles.input_container}>
                            <label>ID</label>
                            <input
                                type="text"
                                placeholder="Digite o id do preso..."
                                name="iddopreso"
                                value={idPreso}
                                onChange={handleIDPresoChange}
                            />
                        </div>
                        <div className={styles.input_container}>
                            <label>RG DO ADVOGADO</label>
                            <input
                                type="text"
                                placeholder="RG DO ADVOGADO"
                                name="rgdoadvogado"
                                value={rgAdvogado}
                                onChange={handleRgAdvogadoChange}
                            />
                        </div>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.crimes}>
                            <h3>CRIMES CONTRA A VIDA</h3>
                            <Checkbox
                                id="artigo1_1"
                                label="Art. 1.1 - Homicídio Doloso Qualificado"
                                checked={checkboxes.artigo1_1}
                                onChange={(event) => handleCheckboxChange(event, "artigo1_1")}
                            />
                            <Checkbox
                                id="artigo1_2"
                                label="Art. 1.2 - Homicídio Doloso"
                                checked={checkboxes.artigo1_2}
                                onChange={(event) => handleCheckboxChange(event, "artigo1_2")}
                            />
                            <Checkbox
                                id="artigo1_3"
                                label="Art. 1.3 - Tentativa de Homicídio"
                                checked={checkboxes.artigo1_3}
                                onChange={(event) => handleCheckboxChange(event, "artigo1_3")}
                            />
                            <Checkbox
                                id="artigo1_4"
                                label="Art. 1.4 - Homicídio Culposo"
                                checked={checkboxes.artigo1_4}
                                onChange={(event) => handleCheckboxChange(event, "artigo1_4")}
                            />
                            <br />
                            <h3>CRIMES CONTRA DIREITOS FUNDAMENTAIS</h3>
                            <Checkbox
                                id="artigo2_1"
                                label="Art. 2.1 - Lesão Corporal"
                                checked={checkboxes.artigo2_1}
                                onChange={(event) => handleCheckboxChange(event, "artigo2_1")}
                            />
                            <Checkbox
                                id="artigo2_2"
                                label="Art. 2.2 - Sequestro"
                                checked={checkboxes.artigo2_2}
                                onChange={(event) => handleCheckboxChange(event, "artigo2_2")}
                            />
                            <Checkbox
                                id="artigo2_3"
                                label="Art. 2.3 - Cárcere Privado"
                                checked={checkboxes.artigo2_3}
                                onChange={(event) => handleCheckboxChange(event, "artigo2_3")}
                            />
                            <br />
                            <h3>CRIMES CONTRA O PATRIMÔNIO</h3>
                            <Checkbox
                                id="artigo3_1"
                                label="Art. 3.1 - Desmanche de Veículos"
                                checked={checkboxes.artigo3_1}
                                onChange={(event) => handleCheckboxChange(event, "artigo3_1")}
                            />
                            <Checkbox
                                id="artigo3_2"
                                label="Art. 3.2 - Furto"
                                checked={checkboxes.artigo3_2}
                                onChange={(event) => handleCheckboxChange(event, "artigo3_2")}
                            />
                            <Checkbox
                                id="artigo3_3"
                                label="Art. 3.3 - Receptação de Veículos"
                                checked={checkboxes.artigo3_3}
                                onChange={(event) => handleCheckboxChange(event, "artigo3_3")}
                            />
                            <Checkbox
                                id="artigo3_4"
                                label="Art. 3.4 - Roubo de Veículos"
                                checked={checkboxes.artigo3_4}
                                onChange={(event) => handleCheckboxChange(event, "artigo3_4")}
                            />
                            <Checkbox
                                id="artigo3_5"
                                label="Art. 3.5 - Furto de Veículos"
                                checked={checkboxes.artigo3_5}
                                onChange={(event) => handleCheckboxChange(event, "artigo3_5")}
                            />
                            <br />
                            <h3>CRIMES DE ROUBOS, FURTOS E SEUS VARIANTES</h3>
                            <Checkbox
                                id="artigo4_1"
                                label="Art. 4.1 - Roubo"
                                checked={checkboxes.artigo4_1}
                                onChange={(event) => handleCheckboxChange(event, "artigo4_1")}
                            />
                            <Checkbox
                                id="artigo4_2"
                                label="Art. 4.2 - Furto a caixa eletrônico"
                                checked={checkboxes.artigo4_2}
                                onChange={(event) => handleCheckboxChange(event, "artigo4_2")}
                            />
                            <Checkbox
                                id="artigo4_3"
                                label="Art. 4.3 - Extorsão"
                                checked={checkboxes.artigo4_3}
                                onChange={(event) => handleCheckboxChange(event, "artigo4_3")}
                            />
                            <br />
                            <h3>CRIMES DE PORTE, POSSE E TRÁFICO</h3>
                            <Checkbox
                                id="artigo5_1"
                                label="Art. 5.1 - Posse de peças de armas"
                                checked={checkboxes.artigo5_1}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_1")}
                            />
                            <Checkbox
                                id="artigo5_2"
                                label="Art. 5.2 - Posse de Cápsula"
                                checked={checkboxes.artigo5_2}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_2")}
                            />
                            <Checkbox
                                id="artigo5_3"
                                label="Art. 5.3 - Tráfico de Armas"
                                checked={checkboxes.artigo5_3}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_3")}
                            />
                            <Checkbox
                                id="artigo5_4"
                                label="Art. 5.4 - Porte de Arma Pesada"
                                checked={checkboxes.artigo5_4}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_4")}
                            />
                            <Checkbox
                                id="artigo5_5"
                                label="Art. 5.5 - Porte de Arma Leve"
                                checked={checkboxes.artigo5_5}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_5")}
                            />
                            <Checkbox
                                id="artigo5_6"
                                label="Art. 5.6 - Disparo de Arma"
                                checked={checkboxes.artigo5_6}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_6")}
                            />
                            <Checkbox
                                id="artigo5_7"
                                label="Art. 5.7 - Tráfico de Munições (+100)"
                                checked={checkboxes.artigo5_7}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_7")}
                            />
                            <Checkbox
                                id="artigo5_8"
                                label="Art. 5.8 - Porte de Munição (-100)"
                                checked={checkboxes.artigo5_8}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_8")}
                            />
                            <Checkbox
                                id="artigo5_9"
                                label="Art. 5.9 - Posse de Colete"
                                checked={checkboxes.artigo5_9}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_9")}
                            />
                            <Checkbox
                                id="artigo5_10"
                                label="Art. 5.10 - Posse de Arma Branca"
                                checked={checkboxes.artigo5_10}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_10")}
                            />
                            <Checkbox
                                id="artigo5_11"
                                label="Art. 5.11 - Tráfico de Drogas (+100)"
                                checked={checkboxes.artigo5_11}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_11")}
                            />
                            <Checkbox
                                id="artigo5_12"
                                label="Art. 5.12 - Aviãozinho (6 a 100)"
                                checked={checkboxes.artigo5_12}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_12")}
                            />
                            <Checkbox
                                id="artigo5_13"
                                label="Art. 5.13 - Posse de Componentes Narcóticos"
                                checked={checkboxes.artigo5_13}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_13")}
                            />
                            <Checkbox
                                id="artigo5_14"
                                label="Art. 5.14 - Posse de Drogas (1 a 5)"
                                checked={checkboxes.artigo5_14}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_14")}
                            />
                            <Checkbox
                                id="artigo5_15"
                                label="Art. 5.15 - Dinheiro Sujo"
                                checked={checkboxes.artigo5_15}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_15")}
                            />
                            <Checkbox
                                id="artigo5_16"
                                label="Art. 5.16 - Contrabando"
                                checked={checkboxes.artigo5_16}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_16")}
                            />
                            <Checkbox
                                id="artigo5_17"
                                label="Art. 5.17 - Descaminho"
                                checked={checkboxes.artigo5_17}
                                onChange={(event) => handleCheckboxChange(event, "artigo5_17")}
                            />
                        </div>
                        <div className={styles.crimes}>
                            <h3>CRIMES CONTRA A ORDEM PÚBLICA</h3>
                            <Checkbox
                                id="artigo6_1"
                                label="Art. 6.1 - Falsidade ideológica"
                                checked={checkboxes.artigo6_1}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_1")}
                            />
                            <Checkbox
                                id="artigo6_2"
                                label="Art. 6.2 - Formação de quadrilha"
                                checked={checkboxes.artigo6_2}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_2")}
                            />
                            <Checkbox
                                id="artigo6_3"
                                label="Art. 6.3 - Apologia ao crime"
                                checked={checkboxes.artigo6_3}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_3")}
                            />
                            <Checkbox
                                id="artigo6_4"
                                label="Art. 6.4 - Posse de arma em público"
                                checked={checkboxes.artigo6_4}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_4")}
                            />
                            <Checkbox
                                id="artigo6_5"
                                label="Art. 6.5 - Suborno"
                                checked={checkboxes.artigo6_5}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_5")}
                            />
                            <Checkbox
                                id="artigo6_6"
                                label="Art. 6.6 - Ameaça"
                                checked={checkboxes.artigo6_6}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_6")}
                            />
                            <Checkbox
                                id="artigo6_7"
                                label="Art. 6.7 - Falsa comunicação de crime"
                                checked={checkboxes.artigo6_7}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_7")}
                            />
                            <Checkbox
                                id="artigo6_8"
                                label="Art. 6.8 - Uso indevido de 190/192"
                                checked={checkboxes.artigo6_8}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_8")}
                            />
                            <Checkbox
                                id="artigo6_10"
                                label="Art. 6.10 - Posse de itens ilegais"
                                checked={checkboxes.artigo6_10}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_10")}
                            />
                            <Checkbox
                                id="artigo6_11"
                                label="Art. 6.11 - Assédio moral"
                                checked={checkboxes.artigo6_11}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_11")}
                            />
                            <Checkbox
                                id="artigo6_12"
                                label="Art. 6.12 - Atentado ao pudor"
                                checked={checkboxes.artigo6_12}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_12")}
                            />
                            <Checkbox
                                id="artigo6_13"
                                label="Art. 6.13 - Vandalismo"
                                checked={checkboxes.artigo6_13}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_13")}
                            />
                            <Checkbox
                                id="artigo6_14"
                                label="Art. 6.14 - Dano ao Patrimônio Público"
                                checked={checkboxes.artigo6_14}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_14")}
                            />
                            <Checkbox
                                id="artigo6_15"
                                label="Art. 6.15 - Invasão de propriedade"
                                checked={checkboxes.artigo6_15}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_15")}
                            />
                            <Checkbox
                                id="artigo6_16"
                                label="Art. 6.16 - Abuso de autoridade"
                                checked={checkboxes.artigo6_16}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_16")}
                            />
                            <Checkbox
                                id="artigo6_17"
                                label="Art. 6.17 - Uso de máscara"
                                checked={checkboxes.artigo6_17}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_17")}
                            />
                            <Checkbox
                                id="artigo6_18"
                                label="Art. 6.18 - Uso de equipamentos restritos"
                                checked={checkboxes.artigo6_18}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_18")}
                            />
                            <Checkbox
                                id="artigo6_19"
                                label="Art. 6.19 - Omissão de socorro"
                                checked={checkboxes.artigo6_19}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_19")}
                            />
                            <Checkbox
                                id="artigo6_20"
                                label="Art. 6.20 - Tentativa de fuga"
                                checked={checkboxes.artigo6_20}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_20")}
                            />
                            <Checkbox
                                id="artigo6_21"
                                label="Art. 6.21 - Desacato 1"
                                checked={checkboxes.artigo6_21}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_21")}
                            />
                            <Checkbox
                                id="artigo6_22"
                                label="Art. 6.22 - Desacato 2"
                                checked={checkboxes.artigo6_22}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_22")}
                            />
                            <Checkbox
                                id="artigo6_23"
                                label="Art. 6.23 - Desacato 3"
                                checked={checkboxes.artigo6_23}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_23")}
                            />
                            <Checkbox
                                id="artigo6_24"
                                label="Art. 6.24 - Resistência a prisão"
                                checked={checkboxes.artigo6_24}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_24")}
                            />
                            <Checkbox
                                id="artigo6_25"
                                label="Art. 6.25 - Réu Reincidente"
                                checked={checkboxes.artigo6_25}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_25")}
                            />
                            <Checkbox
                                id="artigo6_26"
                                label="Art. 6.26 - Cúmplice"
                                checked={checkboxes.artigo6_26}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_26")}
                            />
                            <Checkbox
                                id="artigo6_27"
                                label="Art. 6.27 - Obstrução à Justiça"
                                checked={checkboxes.artigo6_27}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_27")}
                            />
                            <Checkbox
                                id="artigo6_28"
                                label="Art. 6.28 - Ocultação de Provas"
                                checked={checkboxes.artigo6_28}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_28")}
                            />
                            <Checkbox
                                id="artigo6_29"
                                label="Art. 6.29 - Zaralho em recrutamento policial"
                                checked={checkboxes.artigo6_29}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_29")}
                            />
                            <Checkbox
                                id="artigo6_30"
                                label="Art. 6.30 - Prisão Militar"
                                checked={checkboxes.artigo6_30}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_30")}
                            />
                            <Checkbox
                                id="artigo6_31"
                                label="Art. 6.31 - Prevaricação"
                                checked={checkboxes.artigo6_31}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_31")}
                            />
                            <Checkbox
                                id="artigo6_32"
                                label="Art. 6.32 - Invasão de Departamento Policial"
                                checked={checkboxes.artigo6_32}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_32")}
                            />
                            <Checkbox
                                id="artigo6_33"
                                label="Art. 6.33 - Vadiagem"
                                checked={checkboxes.artigo6_33}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_33")}
                            />
                            <Checkbox
                                id="artigo6_34"
                                label="Art. 6.34 - Desobediência"
                                checked={checkboxes.artigo6_34}
                                onChange={(event) => handleCheckboxChange(event, "artigo6_34")}
                            />
                            <br />
                            <h3>CRIMES DE TRÂNSITO</h3>
                            <Checkbox
                                id="artigo7_1"
                                label="Art. 7.1 - Condução imprudente"
                                checked={checkboxes.artigo7_1}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_1")}
                            />
                            <Checkbox
                                id="artigo7_2"
                                label="Art. 7.2 - Dirigir na contra mão"
                                checked={checkboxes.artigo7_2}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_2")}
                            />
                            <Checkbox
                                id="artigo7_3"
                                label="Art. 7.3 - Alta velocidade"
                                checked={checkboxes.artigo7_3}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_3")}
                            />
                            <Checkbox
                                id="artigo7_4"
                                label="Art. 7.4 - Poluição sonora"
                                checked={checkboxes.artigo7_4}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_4")}
                            />
                            <Checkbox
                                id="artigo7_5"
                                label="Art. 7.5 - Corridas Ilegais"
                                checked={checkboxes.artigo7_5}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_5")}
                            />
                            <Checkbox
                                id="artigo7_6"
                                label="Art. 7.6 - Uso excessivo de insulfilm"
                                checked={checkboxes.artigo7_6}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_6")}
                            />
                            <Checkbox
                                id="artigo7_7"
                                label="Art. 7.7 - Veículo muito danificado"
                                checked={checkboxes.artigo7_7}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_7")}
                            />
                            <Checkbox
                                id="artigo7_8"
                                label="Art. 7.8 - Veículo Ilegalmente estacionado"
                                checked={checkboxes.artigo7_8}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_8")}
                            />
                            <Checkbox
                                id="artigo7_9"
                                label="Art. 7.9 - Impedir o fluxo do tráfego"
                                checked={checkboxes.artigo7_9}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_9")}
                            />
                            <Checkbox
                                id="artigo7_10"
                                label="Art. 7.10 - Colisão Proposital em viatura policial"
                                checked={checkboxes.artigo7_10}
                                onChange={(event) => handleCheckboxChange(event, "artigo7_10")}
                            />
                        </div>


                        <div className={styles.crimes}>
                            <h3>CALCULADORA PENAL</h3>
                            <br />
                            <div className={styles.input_container}>
                                <label>Pena Total</label>
                                <input
                                    type="text"
                                    placeholder="0"
                                    value={penaTotal}
                                    readOnly
                                    name="penaPreso"
                                />
                            </div>
                            <div className={styles.input_container}>
                                <label>Multa Total</label>
                                <input
                                    type="text"
                                    placeholder="R$ 0,00"
                                    value={`R$ ${multaTotal.toFixed(2)}`}
                                    readOnly
                                    name="multaPreso"
                                />
                            </div>
                            <div className={styles.input_container}>
                                <label>Fiança</label>
                                <input
                                    type="text"
                                    placeholder="R$ 00,00"
                                    value={`R$ ${fiancaTotal.toFixed(2)}`}
                                    readOnly
                                    name="fiancaPreso"
                                />
                            </div>
                            <div>
                                <Checkbox
                                    id="possuiAdvogado"
                                    label="🔹 Advogado constituído."
                                    checked={checkboxes.possuiAdvogado}
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    id="possuiMandado"
                                    label="🔹 Mandado de busca e apreensão."
                                    checked={checkboxes.possuiMandado}
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    id="possuiPorteArma"
                                    label="📋 Possui porte de armas."
                                    checked={checkboxes.possuiPorteArma}
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    id="pagouFianca"
                                    label="💰 Deseja pagar a fiança."
                                    checked={checkboxes.pagouFianca}
                                    onChange={handleCheckboxChange}
                                />
                                <br />
                            </div>

                            <div>
                                <h4>Atenuantes</h4>
                                {Object.keys(resumosArtigos).map((artigoId) => (
                                    <p key={artigoId}>{resumosArtigos[artigoId]}</p>
                                ))}
                            </div>
                        </div>


                    </div>

                </form>
            </div>
            <br />
            <br />
        </div>
    );
}

export default CodigoPenalPage;
