import React from 'react';
import { Link } from 'react-router-dom'; // Importar o Link
import styles from './style.module.scss';

function Header() {

  return (
    <header className={styles.appHeader}>
      <div className={styles.linksCategorias}>
        <Link className={styles.links} to="/">Home</Link>
        <Link className={styles.links} to="/policia">Policia</Link>
        <Link className={styles.links} to="/juridico">Jurídico</Link>
        <Link className={styles.links} to="/hospital">Hospital</Link>
        <Link className={styles.links} to="/mecanica">Mecânica</Link>
      </div>
    </header>
  );
}

export default Header;
