import React from 'react';
import Header from '../../Components/Header';
import styles from './style.module.scss';

const HomePage = () => {
    return (
        <>
            <Header />
            <div className={styles.container}>
                <div className={styles.content}>
                    <h1 className={styles.title}>S.U.I</h1>
                    <h2 className={styles.subtitle}>Sistema Unificado de Informações</h2>
                    <p className={styles.description}>
                        Sistema feito e criado pela SertãoSul Group em parceria com a Maju Store.
                    </p>
                </div>
            </div>
        </>
    );
}

export default HomePage;
