import React, { useState, useEffect } from 'react';
import Header from '../../../Components/Header';
import './boletimocorrencia.css'

function BoletimOcorrenciaPage() {
    // Função para formatar a data no formato "DD/MM/YYYY"
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Função para obter a data atual
    const getCurrentDate = () => {
        const now = new Date();
        return now.toISOString().slice(0, 10); // Retorna a data no formato "YYYY-MM-DD"
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        dataBO: getCurrentDate(), // Definir a data atual para dataBO
        dataOcorrencia: getCurrentDate(), // Definir a data atual para dataOcorrencia
        tipoOcorrencia: '',
        nome: '',
        idade: '',
        passaporte: '',
        rg: '',
        nomeAutor: '',
        passaporteAutor: '',
        nomePartes: '',
        passaportePartes: '',
        descricaoOcorrido: '',
        evidencias: ''
    });

    useEffect(() => {
        // Atualizar as datas para a data atual quando o componente for montado
        setFormData(prevState => ({
            ...prevState,
            dataBO: getCurrentDate(),
            dataOcorrencia: getCurrentDate()
        }));
    }, []); // Executar apenas uma vez, quando o componente for montado

    const handleLimpar = () => {
        setFormData({
            dataBO: getCurrentDate(), // Definir a data atual para dataBO
            dataOcorrencia: getCurrentDate(), // Definir a data atual para dataOcorrencia
            tipoOcorrencia: '',
            nome: '',
            idade: '',
            passaporte: '',
            rg: '',
            nomeAutor: '',
            passaporteAutor: '',
            nomePartes: '',
            passaportePartes: '',
            descricaoOcorrido: '',
            evidencias: ''
        });
    };

    const handleSalvar = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const confirmarEnvio = () => {
        const webhookUrl = 'https://discord.com/api/webhooks/1238587084302844025/C6gPirVpjeqWOAUiy2d3aGRxpzoAtYihWY0osPpa7xLuj0SztOe1lBqRXSvKvwW3I4b0';

        const data = {
            ...formData,
            dataBO: formatDate(formData.dataBO),
            dataOcorrencia: formatDate(formData.dataOcorrencia)
        };

        // Substituir os valores vazios por "N/A"
        for (const key in data) {
            if (data[key] === '') {
                data[key] = 'N/A';
            }
        }

        const message = {
            content: 'Novo boletim de ocorrência registrado!',
            embeds: [
                {
                    title: 'Boletim de Ocorrência',
                    description: `
                        **Data:** ${data.dataBO}
                        **Data da Ocorrência:** ${data.dataOcorrencia}
                        **Tipo de Ocorrência:** ${data.tipoOcorrencia}
    
                        **Nome:** ${data.nome}
                        **Idade:** ${data.idade}
                        **Passaporte:** ${data.passaporte}
                        **RG:** ${data.rg}
    
                        **Nome do Autor:** ${data.nomeAutor}
                        **Passaporte do Autor:** ${data.passaporteAutor}
    
                        **Nome das Partes:** ${data.nomePartes}
                        **Passaporte das Partes:** ${data.passaportePartes}
    
                        **Descrição do Ocorrido:**
                        ${data.descricaoOcorrido}
    
                        **Evidências:**
                        ${data.evidencias}`,
                },
            ],
        };

        fetch(webhookUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(message),
        })
            .then(response => {
                if (response.ok) {
                    handleLimpar();
                    alert('Boletim enviado com sucesso para o Discord.')
                } else {
                    console.error('Erro ao enviar mensagem para o Discord:', response.statusText);
                }
            })
            .catch(error => {
                console.error('Erro ao enviar mensagem para o Discord:', error);
            });

        handleLimpar();
        handleCloseModal();
    };

    return (
        <div className="App">
            <Header />
            <div className='contato'>
                <h2 className='sobre'>Boletim de Ocorrência</h2>
            </div>
            <div className='primeira-row'>
                <div className='input-container'>
                    <label>Data</label>
                    <input
                        type="date"
                        placeholder="Data"
                        name="dataBO"
                        value={formData.dataBO}
                        onChange={handleChange}
                    />
                </div>
                <div className='input-container'>
                    <label>Data Ocorrência</label>
                    <input
                        type="date"
                        placeholder="Data ocorrência"
                        name="dataOcorrencia"
                        value={formData.dataOcorrencia}
                        onChange={handleChange}
                    />
                </div>
                <div className='select-container'>
                    <div className='input-container'>
                        <label>Tipo de Ocorrência</label>
                        <select
                            name="tipoOcorrencia"
                            value={formData.tipoOcorrencia}
                            onChange={handleChange}
                        >
                            <option value="">Selecione</option>
                            <option value="Furto">Furto</option>
                            <option value="Roubo">Roubo</option>
                            <option value="Agressao">Agressão</option>
                            <option value="Ameaca">Ameaça</option>
                            <option value="Acidente">Acidente</option>
                            <option value="Outros">Outros</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='segunda-row'>
                <form>
                    <fieldset>
                        <legend>Dados Pessoais</legend>
                        <div className='input-container'>
                            <label>Nome</label>
                            <input
                                type="text"
                                placeholder="Nome completo"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container'>
                            <label>Idade</label>
                            <input
                                type="number"
                                placeholder="Idade"
                                name="idade"
                                value={formData.idade}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container'>
                            <label>Passaporte</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container'>
                            <label>RG</label>
                            <input
                                type="text"
                                placeholder="RG"
                                name="rg"
                                value={formData.rg}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className='terceira-row'>
                <form>
                    <fieldset>
                        <legend>Dados Autor</legend>
                        <div className='input-container'>
                            <label>Nome</label>
                            <input
                                type="text"
                                placeholder="Nome completo"
                                name="nomeAutor"
                                value={formData.nomeAutor}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container'>
                            <label>Passaporte</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporteAutor"
                                value={formData.passaporteAutor}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className='quarta-row'>
                <form>
                    <fieldset>
                        <legend>Dados Partes</legend>
                        <div className='input-container'>
                            <label>Nome</label>
                            <input
                                type="text"
                                placeholder="Nome completo"
                                name="nomePartes"
                                value={formData.nomePartes}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container'>
                            <label>Passaporte</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaportePartes"
                                value={formData.passaportePartes}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className='quinta-row'>
                <form>
                    <fieldset>
                        <legend>Descrição do Ocorrido</legend>
                        <div className='input-container'>
                            <textarea
                                placeholder="Descrição do Ocorrido"
                                name="descricaoOcorrido"
                                value={formData.descricaoOcorrido}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className='sexta-row'>
                <form>
                    <fieldset>
                        <legend>Evidências</legend>
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="Colocar link da evidência"
                                name="evidencias"
                                value={formData.evidencias}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className='setima-row'>
                <div className="buttons-container">
                    <button className="btn" onClick={handleLimpar} style={{ color: 'white', background: 'red' }} >Limpar</button>
                    <button className="btn" onClick={handleSalvar} style={{ color: 'white', background: 'green' }} >Salvar</button>
                </div>
            </div>
            {/* Modal */}
            {isModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Confirme os dados:</h2>
                            <p>Data do B.O.: {formData.dataBO || 'N/A'}</p>
                            <p>Data da Ocorrência: {formData.dataOcorrencia || 'N/A'}</p>
                            <p>Tipo de Ocorrência: {formData.tipoOcorrencia || 'N/A'}</p>
                            <p>Nome: {formData.nome || 'N/A'}</p>
                            <p>Idade: {formData.idade || 'N/A'}</p>
                            <p>Passaporte: {formData.passaporte || 'N/A'}</p>
                            <p>RG: {formData.rg || 'N/A'}</p>
                            <p>Nome do Autor: {formData.nomeAutor || 'N/A'}</p>
                            <p>Passaporte do Autor: {formData.passaporteAutor || 'N/A'}</p>
                            <p>Nome das Partes: {formData.nomePartes || 'N/A'}</p>
                            <p>Passaporte das Partes: {formData.passaportePartes || 'N/A'}</p>
                            <p>Descrição do Ocorrido: {formData.descricaoOcorrido || 'N/A'}</p>
                            <p>Evidências: {formData.evidencias || 'N/A'}</p>
                            <div className="modal-buttons">
                                <button onClick={confirmarEnvio} style={{ color: 'white', background: 'green' }} >Confirmar</button>
                                <button onClick={handleCloseModal} style={{ color: 'white', background: 'red' }} >Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BoletimOcorrenciaPage;
