import React from 'react';
import Header from '../../Components/Header';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

function JuridicoPage() {



    return (
        <div className={styles.app}>
            <Header />
            <div>
            </div>
            <div className={styles.contato}>
                <h2 className='sobre'>Seja bem-vindo "nome usuario"!</h2>
                <h2 className='sobre'>Setor Jurídico!</h2>
            </div>
            <div className={styles.categorias}>
                <Link to="/alterarnome">ALTERAÇÃO DE NOME</Link>
                <Link to="/casamento">CASAMENTOS</Link>
                <Link to="/aberturaprocesso">ABERTURA DE PROCESSO</Link>
                <Link to="/juridico">ALVARÁ DE FUNCIONAMENTO</Link>
                <Link to="/juridico">DOCUMENTAÇÃO DE NEGOCIOS</Link>
                <Link to="/juridico">ADOÇÃO DE CRIANÇAS</Link>
                <Link to="/vendaalianca">VENDA DE ALIANÇA</Link>
            </div>
        </div>
    );
}

export default JuridicoPage;
