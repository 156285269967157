import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { setupAPIClient } from '../../services/api';
import styles from './style.module.scss';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [redirectToHome, setRedirectToHome] = useState(false);
    const [user, setUser] = useState('');

    useEffect(() => {
        if (user) {
            console.log('User state is set. Redirecting to home...');
            setRedirectToHome(true);
        }
    }, [user]);


    const handleLogin = async () => {
        try {
            const token = btoa(`${email}:${password}`);
            const response = await setupAPIClient().get('/login', {
                headers: {
                    Authorization: `Basic ${token}`
                }
            });
            const { token: authToken } = response.data;

            localStorage.setItem('token', authToken);

            // Decodificar o token JWT
            const decodedToken = JSON.parse(atob(authToken.split('.')[1]));
            console.log('decodedToken', decodedToken);

            console.log('Redirecting to home...');
            setUser(decodedToken);
            setRedirectToHome(true);
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            setError('Erro ao fazer login. Verifique suas credenciais.');
        }
    };


    if (redirectToHome) {
        return <Navigate to="/" state={{ user }} />;
    }

    const getRandomNumber = (min, max) => {
        return Math.random() * (max - min) + min;
    };

    const createSnowflakes = () => {
        const snowflakes = [];
        // Gera 50 flocos de neve
        for (let i = 0; i < 50; i++) {
            snowflakes.push(
                <div
                    key={i}
                    className={styles.snowflake}
                    style={{
                        left: `${getRandomNumber(0, 100)}vw`, // Posição horizontal aleatória
                        animationDuration: `${getRandomNumber(5, 20)}s`, // Duração da animação aleatória
                    }}
                ></div>
            );
        }
        return snowflakes;
    };

    return (
        <div className={styles.loginContainer}>
            {createSnowflakes()}
            <h1 className={styles.loginTitle}>Bem vindo!</h1>
            <div className={styles.loginForm}>
                <label className={styles.loginLabel}>Email:</label>
                <input
                    type="email"
                    className={styles.loginInput}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label className={styles.loginLabel}>Senha:</label>
                <input
                    type="password"
                    className={styles.loginInput}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className={styles.loginButton} onClick={handleLogin}>Entrar</button>
                {error && <p className={styles.loginError}>{error}</p>}
            </div>
        </div>
    );
}

export default Login;