import React, { useState, useEffect } from 'react';
import Header from '../../../Components/Header';

function PorteArmaPage() {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const getCurrentDate = () => {
        const now = new Date();
        return now.toISOString().slice(0, 10);
    };

    const getDateThirtyDaysFromNow = () => {
        const now = new Date();
        now.setDate(now.getDate() + 30);
        return now.toISOString().slice(0, 10);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        data: getCurrentDate(),
        dataValidade: getDateThirtyDaysFromNow(),
        tipoPorte: '',
        nome: '',
        passaporte: '',
        rg: '',
        telefone: '',
        exame: '',
        comprovantePagamento: ''
    });

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            data: getCurrentDate(),
            dataValidade: getDateThirtyDaysFromNow()
        }));
    }, []);

    const handleLimpar = () => {
        setFormData({
            data: getCurrentDate(),
            dataValidade: getDateThirtyDaysFromNow(),
            tipoPorte: '',
            nome: '',
            passaporte: '',
            rg: '',
            telefone: '',
            exame: '',
            comprovantePagamento: ''
        });
    };

    const handleSalvar = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const confirmarEnvio = () => {
        const webhookUrls = [
            'https://discord.com/api/webhooks/1239456007231639592/dRMEwsJ7isBupqJIlOzbOgm1OMFo0xZGT9_v-_SxkVYr-pX3JP8VKj8phvIdYizPA5wa', // SESEG
            'https://discord.com/api/webhooks/1239401735781613590/Hk250wRDjUxNf40YZIfdoPiYOa0YQutdL8IlCUztvU8lM81KnWntVA7A5tLFli1Z1_P4' // JURIDICO
        ];
    
        const data = {
            ...formData,
            data: formatDate(formData.data),
            dataValidade: formatDate(formData.dataValidade)
        };
    
        // Substituir os valores vazios por "N/A"
        for (const key in data) {
            if (data[key] === '') {
                data[key] = 'N/A';
            }
        }
    
        const message = {
            embeds: [
                {
                    title: 'Porte de Arma',
                    description: `
                        **Data:** ${data.data}
                        **Data Validade:** ${data.dataValidade}
                        **Tipo de Porte:** ${data.tipoPorte}
        
                        **Nome:** ${data.nome}
                        **Passaporte:** ${data.passaporte}
                        **RG:** ${data.rg}
                        **Telefone:** ${data.telefone}
        
                        **Exame Psicotécnico:**
                        ${data.exame}
        
                        **Comprovante Pagamento:**
                        ${data.comprovantePagamento}`,
                },
            ],
        };
    
        // Enviar mensagem para cada URL do webhook
        webhookUrls.forEach(url => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(message),
            })
                .then(response => {
                    if (response.ok) {
                        handleLimpar();
                    } else {
                        console.error('Erro ao enviar mensagem para o Discord:', response.statusText);
                    }
                })
                .catch(error => {
                    console.error('Erro ao enviar mensagem para o Discord:', error);
                });
        });
    
        handleLimpar();
        handleCloseModal();
    };    

    return (
        <div className="App">
            <Header />
            <div className='contato'>
                <h2 className='sobre'>Porte de Arma</h2>
            </div>
            <div className='primeira-row'>
                <div className='input-container'>
                    <label>Data</label>
                    <input
                        type="date"
                        placeholder="Data"
                        name="data"
                        value={formData.data}
                        onChange={handleChange}
                    />
                </div>
                <div className='input-container'>
                    <label>Data Validade</label>
                    <input
                        type="date"
                        placeholder="Data"
                        name="dataValidade"
                        value={formData.dataValidade}
                        onChange={handleChange}
                    />
                </div>
                <div className='select-container'>
                    <div className='input-container'>
                        <label>Tipo de Porte</label>
                        <select
                            name="tipoPorte"
                            value={formData.tipoPorte}
                            onChange={handleChange}
                        >
                            <option value="">Selecione</option>
                            <option value="Glock">Glock</option>
                            <option value="Taser">Taser</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className='segunda-row'>
                <form>
                    <fieldset>
                        <legend>Dados Pessoais</legend>
                        <div className='input-container'>
                            <label>Nome</label>
                            <input
                                type="text"
                                placeholder="Nome completo"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='input-container'>
                            <label>Passaporte</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container'>
                            <label>RG</label>
                            <input
                                type="text"
                                placeholder="RG"
                                name="rg"
                                value={formData.rg}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container'>
                            <label>Telefone:</label>
                            <input
                                type="text"
                                placeholder="Telefone"
                                name="telefone"
                                value={formData.telefone}
                                onChange={handleChange}
                            />
                        </div>

                    </fieldset>
                </form>
            </div>
            <div className='terceira-row'>
                <form>
                    <fieldset>
                        <legend>Exame Psicotécnico</legend>
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="Colocar link do exame"
                                name="exame"
                                value={formData.exame}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className='quinta-row'>
                <form>
                    <fieldset>
                        <legend>Comprovante Pagamento</legend>
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="Colocar link do comprovante"
                                name="comprovantePagamento"
                                value={formData.comprovantePagamento}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className='setima-row'>
                <div className="buttons-container">
                    <button className="btn" onClick={handleLimpar} style={{ color: 'white', background: 'red' }} >Limpar</button>
                    <button className="btn" onClick={handleSalvar} style={{ color: 'white', background: 'green' }} >Salvar</button>
                </div>
            </div>
            {/* Modal */}
            {isModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Confirme os dados:</h2>
                            <p><strong>Data:</strong> {formData.data || 'N/A'}</p>
                            <p><strong>Data Validade:</strong> {formData.dataValidade || 'N/A'}</p>
                            <p><strong>Tipo de Porte:</strong> {formData.tipoPorte || 'N/A'}</p>
                            <p><strong>Nome:</strong> {formData.nome || 'N/A'}</p>
                            <p><strong>Passaporte:</strong> {formData.passaporte || 'N/A'}</p>
                            <p><strong>RG:</strong> {formData.rg || 'N/A'}</p>
                            <p><strong>Telefone:</strong> {formData.telefone || 'N/A'}</p>
                            <p><strong>Exame Psicotécnico:</strong> {formData.exame || 'N/A'}</p>
                            <p><strong>Comprovante Pagamento:</strong> {formData.comprovantePagamento || 'N/A'}</p>
                            <div className="modal-buttons">
                                <button onClick={confirmarEnvio} style={{ color: 'white', background: 'green' }} >Confirmar</button>
                                <button onClick={handleCloseModal} style={{ color: 'white', background: 'red' }} >Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PorteArmaPage;
