import React from 'react';
import './index.css'

function Checkbox({ id, label, checked, onChange, pena, multa }) {
    return (
        <div className="checkbox">
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
                pena={pena}
                multa={multa}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
}

export default Checkbox;
