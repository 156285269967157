import React from 'react';
import Header from '../../Components/Header';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

function HospitalPage() {

    return (
        <div className={styles.app}>
            <Header />
            <div>
            </div>
            <div className={styles.contato}>
                <h2 className='sobre'>Seja bem-vindo "nome usuario"!</h2>
                <h2 className='sobre'>Setor Hospital!</h2>
            </div>
            <div className={styles.categorias}>
                <Link to="/hospital">AAAA</Link>

            </div>
        </div>
    );
}

export default HospitalPage;
