import React, { useState, useEffect } from 'react';
import Header from '../../../Components/Header';

function AberturaProcessoPage() {
    // Função para formatar a data no formato "DD/MM/YYYY"
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        data: '', // Definir a data atual para dataBO
        quantidade: '',
        nome: '',
        passaporte: '',
        rgNoivo: '',
        nomeNoiva: '',
        passaporteNoiva: '',
        rgNoiva: '',
        comprovantePagamento: ''
    });

    useEffect(() => {
        // Atualizar as datas para a data atual quando o componente for montado
        setFormData(prevState => ({
            ...prevState,
            data: '',
        }));
    }, []); // Executar apenas uma vez, quando o componente for montado

    const handleLimpar = () => {
        setFormData({
            data: '', // Definir a data atual para dataBO
            quantidade: '',
            nome: '',
            passaporte: '',
            rgNoivo: '',
            nomeNoiva: '',
            passaporteNoiva: '',
            rgNoiva: '',
            comprovantePagamento: ''
        });
    };

    const handleSalvar = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const confirmarEnvio = () => {
        const webhookUrl = 'https://discord.com/api/webhooks/1240534811421052980/bKsdUHh68a_qR5vlxJyFJmZJcb5YDaG7giiw5u-kwhgb6jMVWMfZnrJwDgeT43KEIB-e';

        const data = {
            ...formData,
            data: formatDate(formData.data),
            dataValidade: formatDate(formData.dataValidade)
        };

        // Substituir os valores vazios por "N/A"
        for (const key in data) {
            if (data[key] === '') {
                data[key] = 'N/A';
            }
        }

        const message = {
            embeds: [
                {
                    title: 'Venda Aliança',
                    description: `
                        **Data:** ${data.data}
                        **Tipo de União:** ${data.quantidade}
    
                        **Nome Noivo:** ${data.nome}
                        **Passaporte:** ${data.passaporte}
    
                        **Comprovante Pagamento:**
                        ${data.comprovantePagamento}`,
                },
            ],
        };

        fetch(webhookUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(message),
        })
            .then(response => {
                if (response.ok) {
                    handleLimpar();
                    alert('Enviado com sucesso para o Discord.');
                } else {
                    console.error('Erro ao enviar mensagem para o Discord:', response.statusText);
                }
            })
            .catch(error => {
                console.error('Erro ao enviar mensagem para o Discord:', error);
            });

        handleLimpar();
        handleCloseModal();
    };

    return (
        <div className="App">
            <Header />
            <div className='contato'>
                <h2 className='sobre'>Abertura de Processo</h2>
            </div>
            <div className='primeira-row'>
            <div className='input-container'>
                    <label>N° Processo</label>
                    <input
                        type="number"
                        placeholder="Data"
                        name="data"
                        value={formData.data}
                        onChange={handleChange}
                    />
                </div>
                <div className='input-container'>
                    <label>Data</label>
                    <input
                        type="date"
                        placeholder="Data"
                        name="data"
                        value={formData.data}
                        onChange={handleChange}
                    />
                </div>
                
            </div>

            <div className='segunda-row'>
                <form>
                    <fieldset>
                        <legend>Dados</legend>
                        <div className='input-container'>
                            <label>Nome</label>
                            <input
                                type="text"
                                placeholder="Nome completo"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='input-container'>
                            <label>Passaporte</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='input-container'>
                            <label>Idade</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='input-container'>
                            <label>RG</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='input-container'>
                            <label>Telefone</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>

                    </fieldset>
                </form>
            </div>
            <div className='terceira-row'>
                <form>
                    <fieldset>
                        <legend>Dados Processado</legend>
                        <div className='input-container'>
                            <label>Nome</label>
                            <input
                                type="text"
                                placeholder="Nome completo"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='input-container'>
                            <label>Passaporte</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='input-container'>
                            <label>Idade</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='input-container'>
                            <label>RG</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='input-container'>
                            <label>Telefone</label>
                            <input
                                type="number"
                                placeholder="ID"
                                name="passaporte"
                                value={formData.passaporte}
                                onChange={handleChange}
                            />
                        </div>

                    </fieldset>
                </form>
            </div>
            <div className='quinta-row'>
                <form>
                    <fieldset>
                        <legend>Comprovante Pagamento</legend>
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="Colocar link do comprovante"
                                name="comprovantePagamento"
                                value={formData.comprovantePagamento}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className='setima-row'>
                <div className="buttons-container">
                    <button className="btn" onClick={handleLimpar} style={{ color: 'white', background: 'red' }} >Limpar</button>
                    <button className="btn" onClick={handleSalvar} style={{ color: 'white', background: 'green' }} >Salvar</button>
                </div>
            </div>
            {/* Modal */}
            {isModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Confirme os dados:</h2>
                            <p><strong>Data:</strong> {formData.data || 'N/A'}</p>
                            <p><strong>Quantidade:</strong> {formData.quantidade || 'N/A'}</p>

                            <p><strong>Nome:</strong> {formData.nome || 'N/A'}</p>
                            <p><strong>Passaporte:</strong> {formData.passaporte || 'N/A'}</p>

                            <p><strong>Comprovante Pagamento:</strong> {formData.comprovantePagamento || 'N/A'}</p>
                            <div className="modal-buttons">
                                <button onClick={confirmarEnvio} style={{ color: 'white', background: 'green' }} >Confirmar</button>
                                <button onClick={handleCloseModal} style={{ color: 'white', background: 'red' }} >Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AberturaProcessoPage;
