import axios from 'axios';
const url = 'http://45.40.99.157:9000';

export function setupAPIClient() {
    const api = axios.create({
        baseURL: url,
    });

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            if (error.response.status === 401) {
                if (typeof window !== 'undefined') {
                    try {

                    } catch (e) {
                        console.error('Erro ao fazer logout:', e);
                    }
                } else {
                    return;
                }
            }
            return Promise.reject(error);
        }
    );

    return api;
}

export default setupAPIClient;
