import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header';

function PoliciaPage(props) {
    const playerName = props.location && props.location.state && props.location.state.playerName;
    const discordData = props.location && props.location.state && props.location.state.discordData;

    console.log('playerName:', playerName);
    console.log('discordData:', discordData);

    return (
        <div className="App">
            <Header />
            <div>
            </div>
            <div className="contato">
                <h2 className='sobre'>Seja bem-vindo "nome usuario"!</h2>
                <h2 className='sobre'>Setor Policial!</h2>
            </div>
            <div className='categorias'>
                <Link to="/codigopenal">Código Penal</Link>
                <Link to="/boletimocorrencia">Boletim de Ocorrência</Link>
                <Link to="/portearma">Porte de Armas</Link>
                <Link to="/policia">Limpeza de Ficha</Link>
                <Link to="/solicitarmandado">Solicitar Mandado</Link>
                <Link to="/policia">Procurados</Link>
            </div>
        </div>
    );
}

export default PoliciaPage;
