import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Homepage from './Pages/Homes';
import Login from './Pages/Login';
import PoliciaPage from './Pages/Policia';
import BoletimOcorrenciaPage from './Pages/Policia/BoletimOcorrencia';
import CodigoPenalPage from './Pages/Policia/CodigoPenal';
import JuridicoPage from './Pages/Juridico';
import AlteracaoNomePage from './Pages/Juridico/AlteracaoNome';
import CasamentoPage from './Pages/Juridico/Casamento';
import PorteArmaPage from './Pages/Policia/PorteArma';
import SolicitarMandadoPage from './Pages/Policia/SolicitarMandado';
import VendaAliancaPage from './Pages/Juridico/VendaAlianca';
import AberturaProcessoPage from './Pages/Juridico/AberturaProcesso';
import MecanicaPage from './Pages/Mecanica';
import HospitalPage from './Pages/Hospital';

function App() {
  const [user, setUser] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUser(decodedToken);
    }
  }, []);

  const isUserAuthenticated = () => {
    return user && user.nome;
  };

  const ProtectedRoute = ({ element }) => {
    if (isUserAuthenticated()) {
      return element;
    } else {
      localStorage.setItem('redirectPath', window.location.pathname);
      return <Navigate to="/login" replace />;
    }
  };

  return (
    <BrowserRouter>
      <Routes>

        <Route path='/login' element={<Login />} />
        <Route path='/' element={<ProtectedRoute element={<Homepage />} />} />

        <Route path='/policia/*' element={<ProtectedRoute element={<PoliciaPage />} />} />
        <Route path='/boletimocorrencia' element={<ProtectedRoute element={<BoletimOcorrenciaPage />} />} />
        <Route path='/codigopenal' element={<ProtectedRoute element={<CodigoPenalPage />} />} />
        <Route path='/portearma' element={<ProtectedRoute element={<PorteArmaPage />} />} />
        <Route path='/solicitarmandado' element={<ProtectedRoute element={<SolicitarMandadoPage />} />} />

        <Route path='/juridico/*' element={<ProtectedRoute element={<JuridicoPage />} />} />
        <Route path='/alterarnome' element={<ProtectedRoute element={<AlteracaoNomePage />} />} />
        <Route path='/casamento' element={<ProtectedRoute element={<CasamentoPage />} />} />
        <Route path='/vendaalianca' element={<ProtectedRoute element={<VendaAliancaPage />} />} />
        <Route path='/aberturaprocesso' element={<ProtectedRoute element={<AberturaProcessoPage />} />} />

        <Route path='/mecanica' element={<ProtectedRoute element={<MecanicaPage />} />} />

        <Route path='/hospital' element={<ProtectedRoute element={<HospitalPage />} />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
